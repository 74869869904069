/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sacramento:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
/* END OF Fonts */

/* Colors */
:root {
  --primary-base: #660066;
  --primary-hover: #660066b2;

  --secondary-base: #660066;

  --secondary-05: #ffc5d336;
  --secondary-1: #ffc5d3;
  --secondary-2: #ff93ae;
  --secondary-3: #fc5780;
  --secondary-4: #dd3a63;
  --secondary-5: #bd1f46;
  --secondary-6: #9b203f;
  --secondary-7: #5a051a;
  --secondary-8: #3c0311;
  --secondary-9: #1d060b;

  --accent-1: #e8e8e8;
  --accent-2: #c1c9d2;
  --accent-3: #697386;
  --accent-4: #7fffdf;

  --white: #ffffff;
  --black: #000000;
}
/* END OF Colors */

* {
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--primary-base);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: var(--secondary-05);
  }
}

.disabled-link {
  pointer-events: none;
}

/* Override Material-ui DateAndTimePickers */
.date-time-picker .MuiInputBase-root,
.date-time-picker .MuiFormControl-root,
.date-time-picker input {
  width: 100% !important;
  /* max-width: 100% !important;
  min-width: 100% !important; */
}
.date-time-picker .MuiInputBase-root::after,
.date-time-picker .MuiInputBase-root::before {
  content: none !important;
  border-bottom: none !important;
}
.date-time-picker input {
  height: 40px !important;
  border: 1px solid var(--accent-2) !important;
  border-radius: 2px !important;
  padding: 0 16px !important;
}

.date .MuiInputBase-input.MuiInput-input {
  border: none !important;
}
/* END OF Override Material-ui DateAndTimePickers */

/* Carousel styles */
.rec.rec-arrow {
  border-radius: 0;
  background-color: white;
  border: none;
  box-shadow: none;
}
.rec.rec-arrow:hover:enabled {
  background-color: white;
  color: black;
  box-shadow: none;
}
.rec .rec-arrow:disabled {
  visibility: hidden;
}
.rec .rec-dot_active:hover {
  box-shadow: 0 0 1px 3px #333;
  background-color: #333;
}
.rec .rec-dot:hover {
  box-shadow: 0 0 1px 3px #333;
}
.rec .rec-dot:focus {
  cursor: pointer;
  box-shadow: 0 0 1px 3px #333;
}
.rec .rec-dot_active {
  box-shadow: 0 0 1px 3px #333;
  background-color: #333;
}

.relative {
  position: relative;
}
.my-bullet_point {
  list-style: none;
  li::before {
    content: '\2022';
    color: #c4c4c4;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
}
.users_details {
  margin-top: 20px;
  border-radius: 4px;
  background-color: #fff;
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: 20px;
}
.content-table {
  border-collapse: collapse;
  margin: 25px 0;
  width: 100%;
}
.content-table thead tr {
  text-align: left;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
}
.content-table th {
  padding: 15px;
}
.content-table td {
  margin-bottom: 5px;
  padding: 15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
.content-table tbody tr {
  border-bottom: 1px solid rgba(33, 63, 125, 0.1);
}
.modal_dots {
  // position: relative;
  position: absolute;
  @media screen and (max-width: 1024px) {
    position: relative;
  }
}
.dropdown-menu {
  position: absolute;
  box-shadow: 3px 5px 20px 0px rgba(0, 0, 0, 0.04);
  background-color: #fff;
  border: 2px solid rgba(84, 95, 125, 0.1);
  border-radius: 8px;
  right: 3px;
  top: 45px;
  z-index: 100;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  width: 200px;
  display: flex;
  flex-direction: column;
  .modal_items {
    padding: 10px 15px;
    align-items: center;
    display: flex;
  }
  .set_red {
    color: red;
  }
  @media screen and (max-width: 1024px) {
    top: 10px;
  }
}
.drop-active {
  opacity: 1;
  visibility: visible;
}
.drop-inactive {
  opacity: 0;
  visibility: hidden;
}
.participants_modal {
  cursor: pointer;
  &:hover {
    background-color: rgba(128, 0, 32, 0.3);
    width: 100%;
    height: 100%;
  }
}
.course-pagination {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  gap: 10px;
}
.course-pagination_num {
  list-style: none;
  display: flex;
  gap: 5px;
  p {
    padding: 10px 15px;
    border: 1px solid #000;
    cursor: pointer;
  }
}
.course-pagination_active {
  background-color: #944d94;
  color: #fff;
}
