.courses-row-layout,
.card-grid-layout {
  padding-bottom: 10px;
  scroll-snap-type: x proximity;

  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  & .course-box-card {
    scroll-snap-align: center;
  }
}
