@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@700&display=swap");

#downloadWrapper {
  padding: 20px;
  font-family: "Nunito", sans-serif;
}
#cert {
  position: relative;
}
#cert img {
  display: block;
  max-width: 100%;
  height: auto;
}
#certificateWrapper p {
  margin-top: -34px;
  width: 500px;
  font-size: 1.1rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
}
.title {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.line-c {
  position: relative;
  width: 200px;
  margin: auto;
}
.line {
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #000;
}
.main-b {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: "Nunito", sans-serif;
  font-size: 25px;
  padding-top: 20px;
}
.design-1 {
  top: -18px;
  left: 230px;
  position: absolute;

  background-color: #fff;
  width: 30px;
  height: 60px;
  transform: rotate(-40deg);
}
.design-2 {
  bottom: -18px;
  left: 230px;
  position: absolute;

  background-color: #fff;
  width: 30px;
  height: 60px;
  transform: rotate(-40deg);
}
.angle {
  background-color: #660066;
  width: 130px;
  position: absolute;
  z-index: 8888;
  right: -50px;
  top: -20px;
  height: 100px;
  transform: rotate(46deg);
}
.angle-2 {
  background-color: #660066;
  width: 130px;
  position: absolute;
  z-index: 8;
  left: -50px;
  bottom: -20px;
  height: 100px;
  transform: rotate(46deg);
}
