.take-lesson-video-wrapper {
  video::-webkit-media-controls {
    &-timeline {
      display: none;
    }

    &-fullscreen-button {
      position: relative;
      z-index: 100;
      transform: translateX(100%);
    }
  }
}
